<template>
  <div>
    <v-app-bar class="topnavbar">
      <div style=" display: flex; flex-direction: row; margin: 0px; justify-content: space-between; width: 100vw; align-content: center;">
        <div style="margin-top: 4px; margin-left: 8px;" align="left">
          <img height="40px" class="brand-logo-class clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/MyMedicine_Logo.svg" @click="openDHPHome()">
        </div>

        <div v-if="!iscustomer" class="iscustomer ? navbardiv : ''" align="right">
          <V-avatar size="45" style="padding-right: 10px">
            <img
              src="https://s3iconimages.mymedicine.com.mm/male.svg"
              alt="Image"
            />
          </V-avatar>
          <v-btn icon >
            <img v-if="username"
              class="navIcon"
              src="https://s3iconimages.mymedicine.com.mm/refresh.svg"
              @click="refresh"
            />
          </v-btn>
        </div>
      </div>
    </v-app-bar>

    <!-- <v-navigation-drawer
  v-model="drawer"
  absolute
  permanent
  stateless
  style="position: absolute; top:0px; height:100vh;padding-left:6px"
  class="sidebar"
  v-if="!iscustomer"
  >
  <v-list nav dense style="margin-top: 50px;">
    <v-list-item-group
    v-model="group"
    active-class="deep-purple--text text--accent-4"
    >


    <v-list-item style="margin-top: 15%" @click="redirectToHomePage">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/Home_doc.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">Home</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'AdminHome' || pageName == 'DoctorHome' "
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item v-if="!isadmin" @click="redirectToAppointmentHistory">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/appointment_history.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">Appointment History</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'AppointmentHistory' "
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item v-if="!isadmin" @click="redirectToDoctorAllPatients">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/patients_list.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">View All Patients</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewAllPatientDoc' "
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 2%" @click="redirectToGenerateSlots" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">Generate Slots</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'GenerateSlotsPage'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 2%" @click="redirectToViewAllDoctors" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/view_doctor.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;"> Doctors</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewAllDoctors'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 2%" @click="redirectToViewAllPatients" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/patients_list.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">View Patients</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewAllPatient'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>


    <v-list-item style="margin-top: 2%" v-if="isadmin" @click="redirectToAddDoctors" v-show="false">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/add_doctor.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">Add Doctor</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'createDoctor'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 2%" v-if="isadmin" @click="redirectToAddAdmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/add_admin.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">Add Admin</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'createAdmin'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 2%" v-if="isadmin" @click="redirectToViewSpecialization">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">Specializations</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewSpecialization'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 2%" v-if="!isadmin" @click="redirectToProfile">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/Home_doc.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">View Profile</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'DoctorProfile'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 2%" v-if="isadmin" @click="redirectToCreateAdminAppointment">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 2%;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%" align="left">
            <p style="padding-top: 2%;margin-left: 8%;">Create Appointment</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'adminCreateAppointment'"
            />
            <img
            style="margin-top: 10%;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>



  </v-list-item-group>
  <div style="
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1%;
  ">

  <v-btn
  @click="gotoLoginPage()"
  color="#1467BF"
  style="position:absolute; bottom:0; left:0px; padding: 0% 30%; color: white; text-transform: capitalize;margin: 0px;width: 100%; border-radius: 0px;"
  >
  <div style="display: flex; flex-direction: row; position: absolute: bottom: 0px">
    <div>
     <img style="margin-right:5px;  padding-top:5%;"
     src="https://s3iconimages.mymedicine.com.mm/admin_logout.svg"
     />
   </div>
   <div>
     <span> <div style="margin-top: 5px;">
     Logout </div>
   </span>
 </div>
</div>

</v-btn>
</div>

</v-list>

</v-navigation-drawer> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NavBar",
  props: ["username", "pageName"],
  mounted() {
    let userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.isKBZApp = true
    }
    if (this.$router.currentRoute.fullPath.split("/")[1] == "admin") {
      this.isadmin = true;
    } else {
      this.isadmin = false;
    }
    if (
      this.$router.currentRoute.fullPath.split("/")[1] == "customer" ||
      this.$router.currentRoute.fullPath.split("/")[2] == "videoCall" ||
      this.$router.currentRoute.fullPath.split("/")[2] == "videocall" ||
      this.$router.currentRoute.fullPath.split("/")[1][0] == "?"
    ) {
      this.iscustomer = true;
    } else {
      this.iscustomer = false;
    }
    if (!this.$router.currentRoute.fullPath.split("/")[1]) {
      this.iscustomer = true;
    }
  },
  data() {
    return {
      switch1: true,
      drawer: false,
      group: null,
      currentUser: "",
      isadmin: false,
      iscustomer: false,
      Guest: "Guest, ",
      navbardiv: "navbardiv",
      isKBZApp:false,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    openDHPHome() {
      window.open('https://mymedicine.com.mm/', '_blank');
    },
    redirectToLoginPage() {
      this.$router.push({
        name: "Login",
      });
    },
    refresh() {
      this.$router.go();
    },
    redirectToProfile() {
      if (this.isadmin == false) {
        this.$router.push({
          name: "DoctorProfile",
        });
      }
    },
    redirectToHomePage() {
      if (this.$router.currentRoute.fullPath.split("/")[1] == "admin") {
        this.$router.push({
          name: "AdminHome",
        });
      } else if (this.$router.currentRoute.fullPath.split("/")[1] == "doctor") {
        this.$router.push({
          name: "DoctorHome",
        });
      }
    },
    redirectToDoctorAllPatients() {
      this.$router.push({
        name: "DoctorViewAllPatient",
      });
    },
    redirectToAppointmentHistory() {
      this.$router.push({
        name: "AppointmentHistory",
      });
    },
    redirectToViewSpecialization() {
      this.$router.push({
        name: "ViewSpecialization",
      });
    },
    redirectToCreateAdminAppointment() {
      this.$router.push({
        name: "AdminCreateAppointment",
      });
    },
    redirectToGenerateSlots() {
      this.$router.push({
        name: "GenerateSlots",
      });
    },
    redirectToViewAllDoctors() {
      this.$router.push({
        name: "ViewAllDoctors",
      });
    },
    redirectToViewAllPatients() {
      this.$router.push({
        name: "ViewAllPatients",
      });
    },
    redirectToAddDoctors() {
      this.$router.push({
        name: "CreateDoctor",
      });
    },
    redirectToAddAdmin() {
      this.$router.push({
        name: "CreateAdmin",
      });
    },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
        .then((deleteResponse) => {
          if (this.$router.currentRoute.fullPath.split("/")[1] == "admin") {
            this.$cookies.remove("token");
            this.$router.push({
              name: "AdminLogin",
            });
          } else if (
            this.$router.currentRoute.fullPath.split("/")[1] == "doctor"
          ) {
            this.$cookies.remove("doctorToken");
            this.$router.push({
              name: "DoctorLogin",
            });
          }
        })
        .catch((error) => {});
      localStorage.clear();
    },
  },
};
</script>

<style>
@import '../scss/classes.scss';
@import '../scss/global.scss';

.loginButtonClass{
height: 25px;
padding: 0px 0px !important;
background-color: #E4F5FF !important;
color: #1467BF !important;
box-shadow: 0 0px 0px 0px !important;
margin: 0px 0px 0px 10px
}

.topnavbar {
  margin: 0px;
  z-index: 9;
  background: white !important;
  top: 0;
}
.navbardiv{
  width:  100%;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .topnavbar {
  width: 425px;
}
.navbardiv
{
  width: 50%;
}
}
.navIcon {
  width: 32px;
}
/* .theme--light.v-btn.v-btn--has-bg{
  background-color: #E4F5FF !important;
} */
/* .navIcon {
  width: 32px;
}
.navBar {
  background-color: #ffffff !important;
  box-shadow: 0px 2px 15px 0px #00000026 !important;
  z-index: 1;
}
.sideBarClass {
  color: #1467bf;
  padding: 0px;
}
.logoClass {
  width: 100px;
}
@media (max-width: 576px) {
 .sidebar, .logoClass, .titleClass {
  display: none !important;
}
}

@media (min-width: 768px) {
 .titleClass {
  display: none !important;
}
}
@media (max-width: 576px) {
 .titleClass {
  display: block !important;
}
}

@media (max-width: 768px) {
 .titleClass {
  display: block !important;
}
} */
/* @media (min-width: 600px){
  .navBarMainClass{
   width: 600px;

  }
} */
/* @media (min-width: 800px){
  .navBar{
   width: 800px;
   margin-left: auto !important ;
   margin-right: auto !important;

  }} */
</style>